import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faMapMarker, faCalendarAlt, faEnvelope, faBriefcase, faCheck, faRss } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp, faInstagram, faFacebookF, faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
library.add(faMapMarker, faCalendarAlt, faEnvelope, faBriefcase, faCheck, faRss) // solid
library.add(faWhatsapp, faInstagram, faFacebookF, faTwitter, faLinkedinIn) // brand

createApp(App).
    use(router).
    component('font-awesome-icon', FontAwesomeIcon).
    mount('#app')
