<template>
  <section id="ImageMap">
    <div class="content is-small has-text-white">
      <h6 class="title is-6 has-text-white">Sede Nva Córdoba -Servicios</h6>
      <ul>
        <li>Reeducación Postural Global.</li>
        <li>Estudio Digital de la Pisada y Confección de Plantillas.</li>
        <li>Punción Seca y K Taping</li>
        <li>Osteopatia.</li>
        <li>Masoterapia.</li>
      </ul>

      <h3 class="title is-6 has-text-white">Sede La Calera -Servicios</h3>
      <ul>
        <li>Reeducación Postural Global.</li>
        <li>Charlas Informativas Institucionales sobre Postura y Ergonomia.</li>
        <li>Estudio Digital de la Pisada y Confección de Plantillas.</li>
        <li>Punción Seca y K Taping</li>
        <li>Osteopatia.</li>
        <li>Masoterapia.</li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: "ImageMap",
  props: {
    msg: String,
  },
  data: function () {
    return {
      imageMapURL: "../assets/mapa.png",
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
